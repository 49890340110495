/* cspell:ignore msgmore, bupdate, bignore, bnever */
// @ts-expect-error
import browserUpdate from 'browser-update';

browserUpdate({
	required: { e: -2, f: -2, o: -2, s: -2, c: -2 },
	reminder: 0,
	reminderClosed: 0,
	mobile: false,
	api: 2020.06,
	//test: true,
	text: {
		msg: 'RouteTrust does not support your browser. Your web browser ({brow_name}) is out of date.',
		msgmore: '',
		bupdate: 'Update browser',
		bignore: 'Ignore',
		remind: 'You will be reminded in {days} days.',
		bnever: 'Never show again'
	}
});
